import React from 'react'
import classNames from 'classnames'
import { node, oneOf, string } from 'prop-types'
import { displayRatings } from '../../../../../data/ratings.json'
import { useRatingContext } from '@/component/Context/RatingContext'

import styles from './RatingColor.module.scss'

const RatingColor = ({ as, children, className, rating, property }) => {
  const contextRating = useRatingContext()
  const hasRating = rating || contextRating
  const derivedRating = hasRating && (rating || contextRating).toLowerCase()
  const RatingColorEl = as || 'span'

  if (!hasRating) return <>{children}</>

  return (
    <RatingColorEl
      className={classNames(
        styles.RatingColor,
        hasRating && styles[`${property}-${derivedRating}`],
        className
      )}
    >
      {children}
    </RatingColorEl>
  )
}

RatingColor.defaultProps = {
  property: 'color'
}

RatingColor.propTypes = {
  as: string,
  children: node,
  className: string,
  rating: oneOf(displayRatings),
  property: oneOf(['color', 'background-color', 'fill', 'border'])
}

export default RatingColor
